import React, { useState } from 'react';
import './Contact.css'; // Import the CSS file

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    email: '',
    phone: '',
    reason: '',
    message: '',
    captcha: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log('Form submitted:', formData);
  };

  return (
    <div className="contact-page">
      <h2>Contact Us</h2>
      <p>For all inquiries regarding our services please submit the form below.</p>
      
      <form onSubmit={handleSubmit} className="contact-form">
        <div className="form-group">
          <label htmlFor="name">Name *</label>
          <input 
            type="text" 
            id="name" 
            name="name" 
            value={formData.name} 
            onChange={handleChange} 
            required 
          />
        </div>

        <div className="form-group">
          <label htmlFor="company">Company</label>
          <input 
            type="text" 
            id="company" 
            name="company" 
            value={formData.company} 
            onChange={handleChange} 
          />
        </div>

        <div className="form-group">
          <label htmlFor="email">Email *</label>
          <input 
            type="email" 
            id="email" 
            name="email" 
            value={formData.email} 
            onChange={handleChange} 
            required 
          />
        </div>

        <div className="form-group">
          <label htmlFor="phone">Phone</label>
          <input 
            type="tel" 
            id="phone" 
            name="phone" 
            value={formData.phone} 
            onChange={handleChange} 
          />
        </div>

        <div className="form-group">
          <label htmlFor="reason">Reason for Contact *</label>
          <select 
            id="reason" 
            name="reason" 
            value={formData.reason} 
            onChange={handleChange} 
            required
          >
            <option value="">Select</option>
            <option value="general">General Inquiry</option>
            <option value="support">Consulting Services</option>
            <option value="sales">Sales Inquiry</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="message">Message *</label>
          <textarea 
            id="message" 
            name="message" 
            value={formData.message} 
            onChange={handleChange} 
            required
          />
        </div>



        <button type="submit" className="submit-button">Submit</button>
      </form>
    </div>
  );
};

export default Contact;
