import React from 'react';
import './About.css';  // Optional: Create a separate CSS file for styling

const About = () => {
  return (
    <div className="about-page">
      <section className="hero-section">
        <h1 className="hero-text">About Aquila Research</h1>
      </section>
      <section className="about-content">
        <p>
          Aquila Research specializes in providing data-driven consulting services across industries. We leverage advanced data infrastructure and artificial intelligence to deliver actionable insights to our clients.
        </p>
        <p>
          Our services focus on optimizing data pipelines, implementing scalable data solutions, and applying AI models to enhance decision-making processes. We design and deploy systems that enable organizations to harness the full potential of their data.
        </p>
        <p>
          With a strong foundation in data engineering and AI, we provide custom solutions that align with our clients' business objectives, ensuring efficiency and scalability at every step.
        </p>
      </section>
    </div>
  );
};

export default About;
