import React from 'react';
import './Solutions.css';  // Optional: Create a separate CSS file for styling

const Solutions = () => {
  return (
    <div className="solutions-page">
      <h1 className="solutions-title">Our solutions</h1>
      
      <ul className="solutions-list">
        <li>
          <strong>Data Infrastructure:</strong> 
          Building scalable and robust data pipelines to support large-scale data processing and analysis.
        </li>
        <li>
          <strong>Artificial Intelligence (AI) Solutions:</strong> 
          Custom AI models and machine learning algorithms to optimize business operations and provide data-driven insights.
        </li>
        <li>
          <strong>Data Integration:</strong> 
          Seamless integration of disparate data sources to create unified data environments.
        </li>
        <li>
          <strong>Business Intelligence & Analytics:</strong> 
          Providing actionable insights through advanced analytics and data visualization tools.
        </li>
        <li>
          <strong>Consulting & Strategy:</strong> 
          Offering tailored consulting solutions to help businesses leverage data for strategic decision-making and long-term growth.
        </li>
      </ul>
    </div>
  );
};

export default Solutions;
