import React from 'react';
import './Home.css';  // Optional: Create a separate CSS file for styling

const Home = () => {
  return (
    <div className="home-page">
      <section className="hero-section">
        <h1 className="hero-text">Aquila Research</h1>
        <p className="hero-subtext">Data-Driven Solutions for Businesses</p>
      </section>
      
      <section className="home-content">
        <p>
          Aquila Research offers a range of consulting services focused on data-driven solutions. Our expertise lies in building robust data infrastructure and applying advanced AI technologies to streamline operations and improve decision-making.
        </p>
        <p>
          We help organizations enhance their data pipelines, enabling seamless data integration and efficient processing at scale. Our data driven approach helps clients to extract actionable insights from their data, driving performance improvements.
        </p>
      </section>
    </div>
  );
};

export default Home;
