import React, { useState } from 'react';
import './Login.css';  // Assuming the styling is in Login.css

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle login submission
    console.log('Email:', email);
    console.log('Password:', password);
  };

  return (
    <div className="login-page">
      <div className="login-box">
        <h1 className="login-title">Log In</h1>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <div className="password-container">
              <input
                type={passwordVisible ? 'text' : 'password'}
                id="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <i
                className={`fa-solid ${passwordVisible ? 'fa-eye-slash' : 'fa-eye'}`}
                onClick={togglePasswordVisibility}
              ></i>
            </div>
          </div>

          <button type="submit" className="login-button-page">Log In</button>

        </form>

        <div className="login-info">
          <p>This Aquila Research website is by invitation only.</p>
          <ul>
            <li>
              If you have received an invitation, you must first create a login by following the link in your email.
            </li>
            <li>
              If you have not received an invitation, please contact your Aquila Research representative.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Login;
