import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="header">
      <div className="header-left">
        <img src={`${process.env.PUBLIC_URL}/Frame 23.png`} alt="Aquila Research Logo" className="logo" />
        <h1 className="company-name">Aquila Research</h1>
      </div>
      <nav className="navbar">
        <Link to="/">About</Link>
        <Link to="/solutions">Solutions</Link>
        <Link to="/contact">Contact</Link>
        <Link to="/login" className="login-button">Log In</Link> 
      </nav>
    </header>
  );
};

export default Header;
