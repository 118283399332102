import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logo-section">
          <img src={`${process.env.PUBLIC_URL}/Frame 23.png`} alt="Aquila Research Logo" className="footer-logo" />
        </div>
        <div className="footer-links-section">
          <nav className="footer-nav">
            <Link to="/about">About</Link>
            <Link to="/contact">Contact</Link>
          </nav>
        </div>
      </div>
      <div className="footer-bottom">
        <p>© 2024 Aquila Research AB - All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
